import { gql } from '@apollo/client';
// Fragments
import { StoreDetailsFields } from 'api/store/fragments';

export const MerchProductExtendedFields = gql`
  fragment MerchProductExtendedFields on MerchProduct {
    id
    status
    title
    description
    slug
    genders
    hashtags {
      id
      name
    }
    type
    merchProductVariants {
      id
      age
      gender
      stitches
      threads
      merchProductId
      size
      color
      colorCode
      colorCode2
      colorGroupId
      price
      mockupImageFileKey
      mainMockupUrl
      printfulVariantId
      mockupImageURL
      mockupImages {
        id
        merchProductId
        mockupImageURL
        placement
      }
      customImages {
        id
        customMerchImageURL
        imageFileKey
        isMainImage
      }
    }
    mainImageUrl
    minPrice
    store {
      id
      slug
      lastName
      firstName
      role
      storeDetails {
        ...StoreDetailsFields
      }
      sports {
        id
        name
      }
    }
  }
  ${StoreDetailsFields}
`;

export const MerchProductBaseFields = gql`
  fragment MerchProductBaseFields on MerchProduct {
    id
    status
    title
    slug
    minPrice
    merchProductVariants {
      id
      gender
      size
      color
      colorCode
      colorCode2
      customImages {
        id
        customMerchImageURL
        imageFileKey
        isMainImage
      }
      price
      mainMockupUrl
      mockupImageURL
      mockupImages {
        id
        mockupImageURL
      }
      stitches
      threads
    }
    mainImageUrl
    store {
      id
      slug
      lastName
      firstName
      storeDetails {
        id
        storeName
      }
    }
  }
`;
