import React from 'react';
import cn from 'classnames';

import styles from './FourDotsLoader.module.scss';

type FourDotsLoaderProps = {
  wrapperClassName?: string;
  overlayBackgroundColor?: string;
  loaderWrapperBackgroundColor?: string;
};

const dots = Array.from({ length: 4 });

const FourDotsLoader: React.FC<FourDotsLoaderProps> = ({
  wrapperClassName,
  overlayBackgroundColor,
  loaderWrapperBackgroundColor,
}) => {
  return (
    <div
      className={cn(styles.fourDotsLoaderOverlay, wrapperClassName)}
      style={
        overlayBackgroundColor
          ? { backgroundColor: overlayBackgroundColor }
          : {}
      }
      aria-label="loader"
    >
      <div
        className={styles.fourDotsLoaderLoaderWrapper}
        style={
          loaderWrapperBackgroundColor
            ? { backgroundColor: loaderWrapperBackgroundColor }
            : {}
        }
      >
        <div className={styles.fourDotsLoaderLoader} aria-label="Loading">
          {dots.map((_, idx) => (
            <div
              key={idx}
              className={cn(styles.dot, styles[`dot-${idx + 1}`])}
              aria-hidden="true"
              role="presentation"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FourDotsLoader;
