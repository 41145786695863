import { useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { useRouter } from 'next/router';
// Helpers
import { getIfHaveTokenError } from 'helpers/auth';
// Constants
import { HOME } from 'constants/routes';

export const useRedirectOnError = ({
  error,
  redirectTo,
  skipTokenError,
}: {
  error?: ApolloError | boolean | string;
  redirectTo?: string;
  skipTokenError?: boolean;
}): null => {
  const router = useRouter();

  /**
   * 
      !(
        error instanceof ApolloError &&
        error.graphQLErrors.map((e) => e.message).some(getIfHaveTokenError)
      )
   */
  useEffect(() => {
    if (error && !skipTokenError) {
      router.push(redirectTo || HOME);
    } else if (
      // if error is one of token expired errors, don't do redirect in order to try to refresh token
      skipTokenError &&
      error instanceof ApolloError &&
      !error.graphQLErrors.map((e) => e.message).some(getIfHaveTokenError)
    ) {
      router.push(redirectTo || HOME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, router]);

  return null;
};
