import { gql } from '@apollo/client';

export const CREATE_ORDER = gql`
  mutation CreateOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      id
      stripeIntentID
      stripeIntentClientSecret
      discountAmount
    }
  }
`;

export const ESTIMATE_ORDER = gql`
  query EstimateOrder($input: EstimateOrderInput!) {
    estimateOrder(input: $input) {
      discountAmount
      shipping
      shippingAndHandlingFee
      subOrders {
        items {
          memorabiliaId
          price
          merchProductVariant {
            customImages {
              id
              isMainImage
              imageFileKey
              customMerchImageURL
            }
          }
        }
      }
      subtotal
      total
      taxes
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation CancelOrder($id: Float!) {
    cancelOrder(id: $id) {
      id
      subOrders {
        id
        status
      }
    }
  }
`;

export const DELETE_MERCH_PRODUCT = gql`
  mutation DeleteMerchProduct($id: Float!) {
    deleteMerchProduct(id: $id) {
      id
      title
      slug
    }
  }
`;

export const UPLOAD_PRINT_IMAGE = gql`
  mutation UploadPrintImage($input: PrintImageUploadInput!) {
    uploadPrintImage(input: $input) {
      id
      imageURL
    }
  }
`;

export const CREATE_MERCH_PRODUCT_V2 = gql`
  mutation CreateMerchProductV2($input: CreateMerchProductV2Input!) {
    createMerchProductV2(input: $input) {
      id
    }
  }
`;
// TODO: Remove this mutation after checking if it's not used and remove generated types
export const CREATE_MERCH_PRODUCT = gql`
  mutation CreateMerchProduct($input: CreateMerchProductInput!) {
    createMerchProduct(input: $input) {
      id
    }
  }
`;

export const EDIT_MERCH_PRODUCT_V2 = gql`
  mutation EditMerchProductV2($input: EditMerchProductV2Input!) {
    editMerchProductV2(input: $input) {
      id
    }
  }
`;

// TODO: Remove this mutation after checking if it's not used and remove generated types
export const UPDATE_MERCH_PRODUCT = gql`
  mutation UpdateMerchProduct($input: UpdateMerchProductInput!) {
    updateMerchProduct(input: $input) {
      id
    }
  }
`;

export const ADD_MERCH_ITEM_TO_CART = gql`
  mutation AddMerchItemToCart($input: CreateMerchOrderItemInput!) {
    addMerchItemToCart(input: $input) {
      id
      variantId
      amount
    }
  }
`;

export const REMOVE_MERCH_ITEM_FROM_CART = gql`
  mutation RemoveMerchItemFromCart($input: RemoveMerchCartItemInput!) {
    removeMerchItemFromCart(input: $input) {
      id
      variantId
      amount
    }
  }
`;

export const DELETE_PRINT_IMAGE = gql`
  mutation DeletePrintImage($input: PrintImageInput!) {
    deletePrintImage(input: $input) {
      id
      imageURL
    }
  }
`;

export const SET_PRODUCT_SUBORDER_SHIPPED = gql`
  mutation SetProductSuborderShipped($input: SetProductSuborderShippedInput!) {
    setProductSuborderShipped(input: $input) {
      id
      orderId
      status
    }
  }
`;

export const APPROVE_AUTO_GENERATED_LOGOS = gql`
  mutation ApproveAutoGeneratedLogos($input: ApproveAutoGeneratedMerchInput!) {
    approveAutoGeneratedLogos(input: $input) {
      createdAt
      id
      url
      merch {
        id
        slug
        autoSocialMediaPackStatus
        autoSocialPackImages {
          id
          url
          status
        }
      }
    }
  }
`;

export const REJECT_AUTO_GENERATED_LOGOS = gql`
  mutation RejectAutoGeneratedLogos {
    rejectAutoGeneratedLogos {
      createdAt
      id
      url
    }
  }
`;

export const ACCEPT_OPT_IN_MERCH = gql`
  mutation AcceptOptInMerch($input: AcceptOptInMerchInput!) {
    acceptOptInMerch(input: $input) {
      id
      slug
    }
  }
`;

export const CREATE_VARIANT_PRINT_FILE_PRESIGNED_URLS = gql`
  mutation CreateVariantPrintFilePresignedUrls(
    $input: CreateVariantPrintfilePresignedUrlsInput!
  ) {
    createVariantPrintFilePresignedUrls(input: $input) {
      fields
      key
      url
    }
  }
`;
