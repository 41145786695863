import { gql } from '@apollo/client';

export const StoreDetailsFields = gql`
  fragment StoreDetailsFields on StoreDetails {
    id
    storeName
    storeTitle
    description
    bio
    avatarURL
    coverURL
    socialMediaLink
    gender
    athleticLevel
    careerStatus
  }
`;

export const StoreBasicFields = gql`
  fragment StoreBasicFields on Store {
    id
    slug
    lastName
    firstName
    storeDetails {
      id
      storeName
      storeTitle
      description
      bio
      avatarURL
      coverURL
      socialMediaLink
    }
  }
`;

export const StoreFields = gql`
  fragment StoreFields on Store {
    id
    slug
    lastName
    firstName
    storeDetails {
      id
      storeName
      storeTitle
      description
      bio
      avatarURL
      coverURL
      socialMediaLink
    }
    email
    phoneNumber
    status
    role
    sports {
      id
      name
    }
    amas {
      id
      price
      requestedPrice
      videoURL
      daysToResponse
      status
    }
  }
`;
