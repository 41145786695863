import { gql } from '@apollo/client';

export const ThankYouVideoFields = gql`
  fragment ThankYouVideoFields on ThankYouVideo {
    id
    storeId
    videoFileKey
    thankYouVideoUrl
    store {
      id
      slug
    }
  }
`;
